<template>
  <header class="header header-home-two">
    <div class="header-top">
      <div class="container">
        <div class="pull-left left-contact-info">
          <p style="font-size: 15px;"><i class="fa fa-envelope-open"></i>manager@staffangar.ru</p><!--
                -->
          <p style=" font-size: 15px;"><i style="" class="zxp-icon-old-telephone-ringing"></i>+ 7
            (846) 215-10-14
          </p>
          <p style="font-size: 15px;">+ 7 (996) 621-02-20</p>
          <p style="font-size: 15px;">+ 7 (996) 621-11-37</p>
          <p style="font-size: 15px;">+ 7 (960) 840-19-95</p>
        </div><!-- /.pull-left -->
        <div class="pull-right right-contact-info">
          <p style=""><i style="" class="fas fa-clock"></i>Пн - Пт: 8:00 до 20:00</p>
        </div><!-- /.pull-right -->
      </div><!-- /.container -->
    </div><!-- /.header-top -->
    <nav class="navbar navbar-default header-navigation stricky">
      <div class="container clearfix">

        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed zxp-icon-menu" data-toggle="collapse"
            data-target=".main-navigation" aria-expanded="false"> </button>
          <div class="navbar-brand">
            <!-- <img src="assets/img/header-logo-1-2.jpg" alt="Image" /> -->
            <video style="margin-left: 15px;" height="70px" playsinline="" autoplay="" muted="">
              <source src="../../assets/video/RUSS_ANgar_1.mp4" type="video/mp4">
            </video>
          </div>
        </div>

        <div style="overflow-x: hidden !important;" class="collapse navbar-collapse main-navigation mainmenu"
          id="main-nav-bar">

          <ul class="nav navbar-nav navigation-box" style="margin-right: 50px">
            <li> <router-link to="/" style="font-size: 18px;">Главная</router-link> </li>
            <li> <router-link to="/about" style="font-size: 18px;">О нас</router-link> </li>
            <li> <router-link to="/hangar" style="font-size: 18px;">Область применения</router-link> </li>
            <li> <router-link to="/projects" style="font-size: 18px;">Наши проекты</router-link> </li>
            <li> <router-link to="/contacts" style="font-size: 18px;">Контакты</router-link> </li>
            <!-- <li>
              <a href="#">Pages</a>
              <ul class="sub-menu">
                <li> <a href="projects.html">Projects</a> </li>
                <li> <a href="project-single.html">Projects Single</a> </li>
                <li><a href="faq.html">FAQ Page</a></li>
                <li><a href="team.html">Team</a></li>
                <li><a href="testimonials.html">Testimonials</a></li>
              </ul>
            </li> -->
          </ul>
        </div><!-- /.navbar-collapse -->
        <div class="right-side-box">
          <router-link class="btn-2" to="/calculation" style="font-size: 19px; font-weight: 700; border: 1px;">Расчет
            стоимости</router-link>
        </div><!-- /.right-side-box -->
      </div><!-- /.container -->
    </nav>
  </header><!-- /.header -->

  <div class="soc-button-panel">
    <a target="_blank" rel="nofollow noopener" class="soc-panel-item fa-facebook-f"
      href="https://www.facebook.com/StaffOil/"></a>
    <a target="_blank" rel="nofollow noopener" class="soc-panel-item fa-vk" href="https://vk.com/staffoil"></a>
    <a target="_blank" rel="nofollow noopener" class="soc-panel-item fa-twitter" href="https://twitter.com/StaffOil"></a>
    <a target="_blank" rel="nofollow noopener" class="soc-panel-item fa-odnoklassniki"
      href="https://ok.ru/group/61074174902342"></a>
    <a target="_blank" rel="nofollow noopener" class="soc-panel-item fa-youtube-play"
      href="https://www.youtube.com/channel/UC85wQS5DPCrBGnIcpw6u99g"></a>
    <a target="_blank" rel="nofollow noopener" class="soc-panel-item fa-telegram-play" href="https://t.me/StaffOil"></a>
  </div>
</template>

<script>
export default {
  name: 'HeaderNavBar',
}
</script>

<style>
/*! CSS Used from: https://staffangar.ru/css/tilda-grid-3.0.min.css ; media=all */
@media all {

  .fa-facebook-f:before,
  .fa-twitter:before,
  .fa-vk:before,
  .fa-odnoklassniki:before {
    content: '';
  }

  *,
  :after,
  :before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  a,
  div {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

/*! CSS Used from: Embedded */
.soc-button-panel {
  position: fixed;
  top: 55%;
  z-index: 20000;
  left: 10px;
}

.soc-panel-item {
  display: block;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-radius: 7px;
  transition: .2s;
}

.fa-vk {
  background-image: url(https://staffoil.ru/img/set/iconfinder-social-media-applications-32vk-4102593_113806.svg);
  background-repeat: no-repeat;
}

.fa-youtube-play {
  background-image: url(https://staffoil.ru/img/set/youtube_icon_130774.svg);
  background-repeat: no-repeat;
}

.fa-facebook-f {
  background-image: url(https://staffoil.ru/img/set/iconfinder-social-media-applications-1facebook-4102573_113807.svg);
  background-repeat: no-repeat;
}

.fa-twitter {
  background-image: url(https://staffoil.ru/img/set/iconfinder-social-media-applications-6twitter-4102580_113802.svg);
  background-repeat: no-repeat;
}

.fa-odnoklassniki {
  background-image: url(https://staffoil.ru/img/set/ok_icon_130872.svg);
  background-repeat: no-repeat;
}

.fa-instagram {
  background-image: url(https://staffoil.ru/img/set/iconfinder-social-media-applications-3instagram-4102579_113804.svg);
  background-repeat: no-repeat;
}

.fa-telegram-play {
  background-image: url(https://staffoil.ru/img/set/telegram_icon_130816.svg);
  background-repeat: no-repeat;
}

a.soc-panel-item,
a.soc-panel-item:hover {
  color: #fff;
}

/*! CSS Used from: Embedded ; media=screen */
@media screen {
  @media screen {
    * {
      -moz-box-sizing: border-box;
      -opera-sizing: border-box;
      -webkit-box-sizing: border-box;
      -khtml-box-sizing: border-box;
      box-sizing: border-box;
    }

    a {
      outline: none;
    }

    a {
      color: #54b700;
      color: #75ce00;
    }

    a:hover {
      text-decoration: none;
    }
  }

  @media screen {
    * {
      -moz-box-sizing: border-box;
      -opera-sizing: border-box;
      -webkit-box-sizing: border-box;
      -khtml-box-sizing: border-box;
      box-sizing: border-box;
    }

    a {
      outline: none;
    }

    a {
      color: #54b700;
      color: #75ce00;
    }

    a:hover {
      text-decoration: none;
    }
  }

  @media screen {
    * {
      -moz-box-sizing: border-box;
      -opera-sizing: border-box;
      -webkit-box-sizing: border-box;
      -khtml-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

/*! CSS Used from: Embedded */
*,
::after,
::before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

::after,
::before {
  --tw-content: '';
}

a {
  color: inherit;
  text-decoration: inherit;
}

:disabled {
  cursor: default;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scroll-snap-strictness: proximity;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
}


.btn-2 {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 10px 15px 15px 0;
  outline: none;
  border: 1px solid #434F90;
  border-radius: 5px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  background-color: #434F90;
  cursor: pointer;
  user-select: none;
  appearance: none;
  touch-action: manipulation;
}

.btn-2:focus {
  box-shadow: 0 0 0 3px rgb(3 81 193 / 25%);
}

.btn-2:hover {
  background-color: #0351C1;
  border-color: #0351C1;
  color: #fff;
}

@media screen {
  .btn-2 {
    margin: 0px 15px 15px 0;
  }
}
</style>
